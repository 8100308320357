import { Helmet } from 'react-helmet';
import { Box, Container, Grid, Typography } from '@mui/material';
import { HealthCheckItem } from '../../components/dashboard/healthcheck';
import useSettings from '../../hooks/useSettings';
import { useAuthState } from '../../contexts/authContext';
import { useApiQuery } from 'src/hooks/useApiQuery';

const HealthCheck = () => {
  const { loading, error, data } = useApiQuery('healthcheck', 'HealthCheck', {
    keys: [
      'firmwareVersionSoft',
      'firmwareVersionLatest',
      'firmwareVersionSoftBeta',
      'firmwareVersionLatestBeta',
      'firmwareVersionHard',
      'iosVersionSoft',
      'iosBuildNoSoft',
      'iosVersionHard',
      'iosBuildNoHard',
      'androidVersionSoft',
      'androidBuildNoSoft',
      'androidVersionHard',
      'androidBuildNoHard',
      'androidStoreUrl',
      'iosStoreUrl',
      'messageSoftUpdate',
      'messageForceUpdate',
      'messageFirmwareSoftUpdate',
      'messageFirmwareForceUpdate',
      'messageFirmwareSoftUpdateBeta',

      'appCacheInvalidate-policies',
      'appCacheInvalidate-protocols',
      'appCacheInvalidate-consents',
      'appCacheInvalidate-features',
      'appCacheInvalidate-missions',
      'appCacheInvalidate-getAppConfig',
      'appCacheInvalidate-assessments'
    ]
  });

  const { settings } = useSettings();
  const authState = useAuthState();

  const parseConfig = (keyStartsWith: string) => {
    if (!data?.HealthCheck) return [];
    return data?.HealthCheck.filter((item) =>
      item.key.startsWith(keyStartsWith)
    );
  };
  const parseFirmwareConfigs = () => [
    ...parseConfig('firmware'),
    ...parseConfig('messageFirmware')
  ];
  const parseIosConfigs = () => parseConfig('ios');
  const parseAndroidConfigs = () => parseConfig('android');
  const parseAppUpdateMessagingConfigs = () => [
    ...parseConfig('messageSoftUpdate'),
    ...parseConfig('messageForceUpdate')
  ];
  const parseCacheConfigs = () => {
    if (!data?.HealthCheck) return [];

    return [
      {
        key: 'appCacheInvalidate-policies',
        value: data.HealthCheck.find(
          (item) => item.key === 'appCacheInvalidate-policies'
        )?.value
      },
      {
        key: 'appCacheInvalidate-protocols',
        value: data.HealthCheck.find(
          (item) => item.key === 'appCacheInvalidate-protocols'
        )?.value
      },
      {
        key: 'appCacheInvalidate-consents',
        value: data.HealthCheck.find(
          (item) => item.key === 'appCacheInvalidate-consents'
        )?.value
      },
      {
        key: 'appCacheInvalidate-features',
        value: data.HealthCheck.find(
          (item) => item.key === 'appCacheInvalidate-features'
        )?.value
      },
      {
        key: 'appCacheInvalidate-missions',
        value: data.HealthCheck.find(
          (item) => item.key === 'appCacheInvalidate-missions'
        )?.value
      },
      {
        key: 'appCacheInvalidate-getAppConfig',
        value: data.HealthCheck.find(
          (item) => item.key === 'appCacheInvalidate-getAppConfig'
        )?.value
      },
      {
        key: 'appCacheInvalidate-assessments',
        value: data.HealthCheck.find(
          (item) => item.key === 'appCacheInvalidate-assessments'
        )?.value
      }
    ];
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: HealthCheck | Sens.ai</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            spacing={1}
            item
            xs={12}
          >
            <Grid item>
              <Typography fontSize="" color="textPrimary" variant="h5">
                HealthCheck
              </Typography>
            </Grid>
          </Grid>
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            spacing={1}
            item
            xs={12}
          >
            <Grid item sx={{ flexGrow: 1 }}>
              <Typography color="textSecondary" variant="overline">
                Firmware Configs
              </Typography>
              {authState.roles?.includes('BETA_FIRMWARE') && (
                <p>
                  *** WARNING YOU ARE A BETA USER THESE VALUES MAY NOT BE
                  ACCURATE ***
                </p>
              )}
              <HealthCheckItem
                configItems={parseFirmwareConfigs()}
                isLoading={loading}
              />
              <Typography color="textSecondary" variant="overline">
                iOS Configs
              </Typography>
              <HealthCheckItem
                configItems={parseIosConfigs()}
                isLoading={loading}
              />
              <Typography color="textSecondary" variant="overline">
                Android Configs
              </Typography>
              <HealthCheckItem
                configItems={parseAndroidConfigs()}
                isLoading={loading}
              />
              <Typography color="textSecondary" variant="overline">
                App Update Messaging
              </Typography>
              <HealthCheckItem
                configItems={parseAppUpdateMessagingConfigs()}
                isLoading={loading}
              />
              <Typography color="textSecondary" variant="overline">
                App Cache Invalidation
              </Typography>
              <HealthCheckItem
                configItems={parseCacheConfigs()}
                inputIsDateNowAction={true}
                description={
                  "Notify the app that a change has occurred in one of the following and that it should invalidate it's cache so that it can use the updated content"
                }
                isLoading={loading}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default HealthCheck;
