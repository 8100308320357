import React from 'react';
import Tooltip from '@mui/material/Tooltip';

const names = new Map([
  [
    'firmwareVersionSoft',
    {
      name: 'Lowest compatible firmware version soft',
      description:
        'Firmware versions that are lower than this will be prompted that they should update.'
    }
  ],

  [
    'firmwareVersionLatest',
    {
      name: 'Current Firmware Version',
      description: 'Firmware version that headsets will be updated to use.'
    }
  ],
  [
    'firmwareVersionSoftBeta',
    {
      name: '*Lowest compatible firmware version soft (Beta)',
      description:
        'Firmware versions that are lower than this will be prompted that they should update.'
    }
  ],

  [
    'firmwareVersionLatestBeta',
    {
      name: '*Current Firmware Version (Beta)',
      description: 'Firmware version that headsets will be updated to use.'
    }
  ],
  [
    'firmwareVersionHard',
    {
      name: 'Lowest compatible firmware version hard',
      description:
        'Firmware versions that are lower than this will be prompt that they must update to continue using the headset.'
    }
  ],
  [
    'iosVersionSoft',
    {
      name: 'Lowest compatible iOS version soft',
      description:
        'iOS versions that are lower than this will be prompted that they should update.'
    }
  ],
  [
    'iosBuildNoSoft',
    {
      name: 'Lowest compatible iOS build number soft',
      description:
        'iOS build numbers that are lower than this will be prompted that they should update'
    }
  ],
  [
    'iosVersionHard',
    {
      name: 'Lowest compatible iOS version hard',
      description:
        'iOS versions that are lower than this will be prompt that they must update to continue using the app'
    }
  ],
  [
    'iosBuildNoHard',
    {
      name: 'Lowest compatible iOS build number hard',
      description:
        'iOS build numbers that are lower than this will be prompted that they must update to continue using the app'
    }
  ],
  [
    'androidVersionSoft',
    {
      name: 'Lowest compatible android version soft',
      description:
        'Android versions that are lower than this will be prompted that they should update.'
    }
  ],
  [
    'androidBuildNoSoft',
    {
      name: 'Lowest compatible android build number soft',
      description:
        'Android build numbers that are lower than this will be prompted that they should update'
    }
  ],
  [
    'androidVersionHard',
    {
      name: 'Lowest compatible android version hard',
      description:
        'Android versions that are lower than this will be prompted that they must update to continue using the app'
    }
  ],
  [
    'androidBuildNoHard',
    {
      name: 'Lowest compatible android build number hard',
      description:
        'Android build numbers that are lower than this will be prompted that they must update to continue using the app'
    }
  ],
  [
    'androidStoreUrl',
    { name: 'Android store URL', description: 'Url for the Android store' }
  ],
  [
    'iosStoreUrl',
    { name: 'iOS store URL', description: 'Url for the iOS store' }
  ],
  [
    'messageSoftUpdate',
    {
      name: 'Message soft update',
      description: 'Message to display when soft update required'
    }
  ],
  [
    'messageForceUpdate',
    {
      name: 'Message force update',
      description: 'Message to display when force update required'
    }
  ],
  [
    'messageFirmwareSoftUpdate',
    {
      name: 'Message Headset Update Availible',
      description: 'Message to display when device firmware update is availible'
    }
  ],
  [
    'messageFirmwareSoftUpdateBeta',
    {
      name: 'Message Headset Update Availible (Beta)',
      description: 'Message to display when device firmware update is availible'
    }
  ],
  [
    'messageFirmwareForceUpdate',
    {
      name: 'Message Headset Update Required',
      description: 'Message to display when device firmware update is required'
    }
  ],
  [
    'appCacheInvalidate-policies',
    {
      name: 'Policies',
      description: 'The apps local cache for Policy nodes'
    }
  ],
  [
    'appCacheInvalidate-protocols',
    {
      name: 'Protocols',
      description: 'The apps local cache for Protocol nodes'
    }
  ],
  [
    'appCacheInvalidate-consents',
    {
      name: 'Consent',
      description: 'The apps local cache for Consent nodes'
    }
  ],
  [
    'appCacheInvalidate-features',
    {
      name: 'Feature',
      description: 'The apps local cache for Feature nodes'
    }
  ],
  [
    'appCacheInvalidate-missions',
    {
      name: 'Missions',
      description: 'The apps local cache for Mission nodes'
    }
  ],
  [
    'appCacheInvalidate-getAppConfig',
    {
      name: 'AppConfig',
      description: 'The apps local cache for App Configuration nodes'
    }
  ],
  [
    'appCacheInvalidate-assessments',
    {
      name: 'Assessment',
      description: 'The apps local cache for Policy nodes'
    }
  ]
]);

const HealthCheckItemDescription: React.FC<{ itemKey: string }> = ({
  itemKey
}) => {
  return (
    <Tooltip title={names.get(itemKey).description} placement="top-start">
      <div style={{ cursor: 'pointer' }}>{names.get(itemKey).name}</div>
    </Tooltip>
  );
};

export default HealthCheckItemDescription;
