import { gql } from '@apollo/client';

//temp fetch of specific user's erp data for initial testing
export const GET_USER_ERP = `
  query ErpAnnotation(
    $offset: Int!
    $minAgeMonths: Float!
    $maxAgeMonths: Float!
  ) {
    ErpAnnotation(
      offset: $offset
      minAgeMonths: $minAgeMonths
      maxAgeMonths: $maxAgeMonths
    ) {
      id
      qScores {
        key
        score
      }
      assessment {
        id
        assessmentId
        assessmentNumber

        appVersion
        deviceFirmwareVersion
        state
        ageMonths
        finished {
          formatted
        }

        phaseResults {
          phaseId
          type
          ... on AssessmentPhaseResultGeniusPulse {
            annotatedConfidenceLevel
            annotationFlagReview
            didIgnoreEmfNotice
            metrics {
              id
              peakTime
              centerTime
              amplitude
              numTrials
              lowNumTrials
              notFound
              type
              fromResult
              segmentType
              channel
            }
            annotatedMetrics {
              id
              peakTime
              centerTime
              amplitude
              numTrials
              lowNumTrials
              notFound
              type
              fromResult
              segmentType
              channel
            }
            reactionTimes {
              rt
              trialCount
              type
              id
            }
            accuracies {
              id
              type
              percent
              trialCount
            }
            segments {
              id
              segmentId
              data
              trialCount
              avgNoiseLvl
            }
          }
        }
      }
    }
  }
`;

export const ANNOTATE_ERP = `
  mutation AnnotateErp(
    $assessmentId: String!
    $annoatedResults: [ErpMetricResultInput]
    $confidenceLevel: Int!
    $flagForReview: Boolean!
    $inverted: Boolean!
    $poorData: Boolean!
  ) {
    AnnotateErp(
      input: {
        assessmentId: $assessmentId
        annoatedResults: $annoatedResults
        confidenceLevel: $confidenceLevel
        flagForReview: $flagForReview
        inverted: $inverted
        poorData: $poorData
      }
    )
  }
`;
